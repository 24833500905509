import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import BoxTypo from "../components/BoxTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = (props) => {
  const { textColor, BGColor } = props;
  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      menuBar: file(relativePath: { eq: "menuBotton1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden smDown>
        <Box width="100%" bgcolor={BGColor}>
          <Box
            width="90%"
            m={"auto"}
            py={4}
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex={99}
            // style={{ backgroundColor: "#F7F6F4" }}
          >
            <Grid container wrap="wrap" alignItems="center">
              <Grid item xs={4}>
                <AnchorLink to="/">
                  <BoxTypo
                    color={textColor}
                    fs="20px"
                    ls="6px"
                    margin="auto"
                    textAlign="Left"
                  >
                    PICCANTE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/ilmacaone" stripHash>
                  <BoxTypo
                    ls="0px"
                    color={textColor}
                    margin="auto"
                    textAlign="center"
                  >
                    TRIPPERIA IL MACAONE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/catervre" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    Catervre
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/piccante" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    人吉ぴっかんて
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/pivot" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    Pivot
                  </BoxTypo>
                </AnchorLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box style={{ backgroundColor: "#F7F6F4" }}>
          <Box
            width="90%"
            m={"auto"}
            py={4}
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex={99}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <Link to="/">
                  <BoxTypo fs="20px" color={textColor} ls="6px">
                    PICCANTE
                  </BoxTypo>
                </Link>
              </Grid>
              <Grid item xs={2}>
                {isOpen ? (
                  <Button
                    style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                    onClick={() => setOpen((c) => !c)}
                  >
                    <Box style={{ width: "50%", maxWidth: "40px" }}>
                      <Img fluid={data.close.childImageSharp.fluid} />
                    </Box>
                  </Button>
                ) : (
                  <Button
                    style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                    onClick={() => setOpen((c) => !c)}
                  >
                    <Box style={{ width: "70%", maxWidth: "40px" }}>
                      <Img fluid={data.menuBar.childImageSharp.fluid} />
                    </Box>
                  </Button>
                )}
              </Grid>
            </Grid>
            <Drawer
              anchor={"left"}
              open={isOpen}
              style={{ width: "100%" }}
              transitionDuration="1500"
            >
              <Box width="100vw" height="100vh" bgcolor="#F7F6F4">
                <Box width="90%" margin="auto" pt="32px">
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={6}>
                      <BoxTypo ls="6px" fs="20px" color={textColor}>
                        <Link to="/" onClick={() => setOpen((c) => !c)}>
                          PICCANTE
                        </Link>
                      </BoxTypo>
                    </Grid>
                    <Grid item xs={2}>
                      {isOpen ? (
                        <Button
                          style={{
                            width: "100%",
                            maxWidth: "126px",
                            margin: "auto",
                          }}
                          onClick={() => setOpen((c) => !c)}
                        >
                          <Box style={{ width: "50%", maxWidth: "20px" }}>
                            <Img fluid={data.close.childImageSharp.fluid} />
                          </Box>
                        </Button>
                      ) : (
                        <Button
                          style={{
                            width: "100%",
                            maxWidth: "126px",
                            margin: "auto",
                          }}
                          onClick={() => setOpen((c) => !c)}
                        >
                          <Box style={{ width: "70%", maxWidth: "40px" }}>
                            <Img fluid={data.menuBar.childImageSharp.fluid} />
                          </Box>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    height="80vh"
                  >
                    <Box width="100%" textAlign="center">
                      <BoxTypo mt={sp_mtSize} ls="0px" fs="18px">
                        <Link
                          to="/ilmacaone"
                          color={textColor}
                          onClick={() => setOpen(false)}
                        >
                          TRIPPERIA IL MACAONE
                        </Link>
                      </BoxTypo>
                      <BoxTypo mt={sp_mtSize} fs="18px">
                        <Link
                          to="/catervre"
                          color={textColor}
                          onClick={() => setOpen(false)}
                        >
                          Catervre
                        </Link>
                      </BoxTypo>
                      <BoxTypo mt={sp_mtSize} fs="18px">
                        <Link
                          to="/piccante"
                          color={textColor}
                          onClick={() => setOpen(false)}
                        >
                          人吉ぴっかんて
                        </Link>
                      </BoxTypo>
                      <BoxTypo mt={sp_mtSize} fs="18px">
                        <Link
                          to="/pivot"
                          color={textColor}
                          onClick={() => setOpen(false)}
                        >
                          Pivot
                        </Link>
                      </BoxTypo>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default Header;
