import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import BoxTypo from "../components/BoxTypo";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Footer = (props) => {
  const { textColor, BGColor } = props;
  const data = useStaticQuery(graphql`
    query {
      tw_logo: file(relativePath: { eq: "tw_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Hidden smDown>
        <Box margin="auto" bgcolor={BGColor}>
          <Box width="90%" margin="auto" py={4}>
            <Grid container wrap="wrap" alignItems="center">
              <Grid item xs={4}>
                <AnchorLink to="/">
                  <BoxTypo
                    color="#FFFFFF"
                    fs="20px"
                    ls="6px"
                    margin="auto"
                    textAlign="Left"
                  >
                    PICCANTE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/ilmacaone" stripHash>
                  <BoxTypo
                    color={textColor}
                    ls="0px"
                    margin="auto"
                    textAlign="center"
                  >
                    TRIPPERIA IL MACAONE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/catervre" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    Catervre
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/piccante" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    人吉ぴっかんて
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={2}>
                <AnchorLink to="/pivot" stripHash>
                  <BoxTypo color={textColor} margin="auto" textAlign="center">
                    Pivot
                  </BoxTypo>
                </AnchorLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box margin="auto" bgcolor={BGColor}>
          <Box width="90%" margin="auto" py={4}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4}>
                <AnchorLink to="/">
                  <BoxTypo
                    color={textColor}
                    fs="20px"
                    ls="6px"
                    margin="auto"
                    textAlign="Left"
                    pb={5}
                  >
                    PICCANTE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={12} md={2}>
                <AnchorLink to="/ilmacaone" stripHash>
                  <BoxTypo
                    color={textColor}
                    margin="auto"
                    textAlign="Left"
                    ls="0px"
                    pb={3}
                  >
                    TRIPPERIA IL MACAONE
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={12} md={2}>
                <AnchorLink to="/catervre" stripHash>
                  <BoxTypo
                    color={textColor}
                    margin="auto"
                    textAlign="Left"
                    pb={3}
                  >
                    Catervre
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={12} md={2}>
                <AnchorLink to="/piccante" stripHash>
                  <BoxTypo
                    color={textColor}
                    margin="auto"
                    textAlign="Left"
                    pb={3}
                  >
                    人吉ぴっかんて
                  </BoxTypo>
                </AnchorLink>
              </Grid>
              <Grid item xs={12} md={2}>
                <AnchorLink to="/pivot" stripHash>
                  <BoxTypo
                    color={textColor}
                    margin="auto"
                    textAlign="Left"
                    pb={3}
                  >
                    Pivot
                  </BoxTypo>
                </AnchorLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default Footer;
