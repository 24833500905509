import React, { Children } from "react";
import { Box, Hidden, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import "../style/font-style.css";

const WrapBoxTypo = props => {
  return (
    <Box
      {...props}
      lineHeight={props.lh ? props.lh : "200%"}
      fontSize={props.fs ? props.fs : "12px"}
      letterSpacing={props.ls ? props.ls : "2px"}
      fontWeight={props.fw ? props.fw : 500}
    >
      <p
        style={{
          fontFamily: props.isCooperItalic
            ? "CooperMdItalic,yumincho,serif"
            : props.Notosans
            ? "Noto Sans JP, sans-serif"
            : props.Yumincho
            ? "yumincho,serif"
            : props.Luam
            ? "Luam-Bold,Noto Sans JP, sans-serif"
            : "A-OTF-GothicMB101Pr6N-Med,serif"
        }}
      >
        {props.children}
      </p>
    </Box>
  );
};

export default WrapBoxTypo;
